<template>
  <div>
    <v-row>
      <v-col cols="12" md="5">
        <v-row>
          <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly">
            <v-card elevation="0" class="commission-content-holder full-height primary white--text text-center pa-2 mb-3">
              <v-card-text>
                <v-row justify="center" no-gutters>
                  <v-col cols="9" class="mb-3">
                    <label class="white--text">{{ $t(`label.commissionToRedeem`) }}</label>
                  </v-col>
                  <v-col cols="9" class="mb-0">
                    <v-form ref="redeemForm">
                      <app-form-field
                        :customClass="'commission-redeem-field input-field elevation-0 hide-details'"
                        :enabledLabel="false"
                        v-model.trim="redeemCommissionAmt"
                        :readonly="true"
                        :prefix="currencySymbolMapp(currentCurrency)"
                        :rules="validator.requiredRules()"
                      ></app-form-field>
                    </v-form>
                  </v-col>
                  <v-col cols="9">
                    <app-button customClass="dialog-button text-capitalize" :action="redeemCommissionConfirmationDialog" :title="$t(`button.redeem`)"></app-button>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="5" sm="6">
            <v-row no-gutters align="end">
              <v-col cols="12">
                <label>{{ $t(`label.todayCommission`) }}</label>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-card color="primary white--text text-left py-2 pl-6 mb-3">
                  {{ overview.commission_balance | currency(currentCurrency) }}
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters align="end">
              <v-col cols="12">
                <label>{{ $t(`label.monthCommission`) }}</label>
              </v-col>
            </v-row>
            <v-row no-gutters align="start">
              <v-col cols="12">
                <v-card color="primary white--text text-left py-2 pl-6">
                  {{ overview.commission_month | currency(currentCurrency) }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-row>
              <v-col cols="12">
                <div class="tier-container">{{ $t(`label.referralTier`) }}</div>
              </v-col>
            </v-row>
            <v-row no-gutters align="end">
              <v-col cols="6">
                <v-card elevation="0" color="border-left-radius primary white--text text-center pa-2 mb-3 mr-1">
                  {{ $t(`label.total`) }}
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card elevation="0" color="border-right-radius primary white--text text-center pa-2 mb-3 mr-1">
                  {{ totalPoint }}
                </v-card>
              </v-col>
            </v-row>
            <v-row class="referral-tier">
              <v-col cols="12">
                <div class="tier-head pa-1 tier-container">
                  <img src="/static/image/referral/referral-people.svg" width="auto" height="75" alt="krikya-referral" />
                </div>
                <v-card class="primary text-center tier-container">
                  <v-card-text class="pa-1">
                    <v-row no-gutters>
                      <v-col class="no-gutters" cols="12">
                        <label class="detail">{{ memberInfo.name || memberInfo.memberCode }}</label>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <img class="tier-container" src="/static/image/referral/referral_arrow_down.svg" width="auto" height="16" alt="krikya-referral" />
              </v-col>
              <v-col cols="12">
                <div class="tier-head pa-1 tier-container">
                  <img src="/static/image/referral/referral-people.svg" width="auto" height="75" alt="krikya-referral" />
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <img class="tier-info" v-bind="attrs" v-on="on" src="/static/image/referral/referral-info.svg" width="auto" height="25" alt="krikya-referral" />
                    </template>
                    <v-row no-gutters class="tier-tool-tip">
                      <v-col cols="12">
                        {{ stringFormat($t(`label.tier`), 1) }}
                      </v-col>
                      <v-col cols="12">
                        {{ stringFormat($t(`label.commissionTips`), getConfig(1)) }}
                      </v-col>
                    </v-row>
                  </v-tooltip>
                </div>
                <v-card class="primary text-center tier-container">
                  <v-card-text class="pa-1">
                    <v-row no-gutters>
                      <v-col class="no-gutters" cols="12">
                        <label class="detail">{{ overview.downline_lv1 }}</label>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <img class="tier-container" src="/static/image/referral/referral_arrow_down.svg" width="auto" height="16" alt="krikya-referral" />
              </v-col>
              <v-col cols="12">
                <div class="tier-head pa-1 tier-container">
                  <img src="/static/image/referral/referral-people.svg" width="auto" height="75" alt="krikya-referral" />
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <img class="tier-info" v-bind="attrs" v-on="on" src="/static/image/referral/referral-info.svg" width="auto" height="25" alt="krikya-referral" />
                    </template>
                    <v-row no-gutters class="tier-tool-tip">
                      <v-col cols="12">
                        {{ stringFormat($t(`label.tier`), 2) }}
                      </v-col>
                      <v-col cols="12">
                        {{ stringFormat($t(`label.commissionTips`), getConfig(2)) }}
                      </v-col>
                    </v-row>
                  </v-tooltip>
                </div>
                <v-card class="primary text-center tier-container">
                  <v-card-text class="pa-1">
                    <v-row no-gutters>
                      <v-col class="no-gutters" cols="12">
                        <label class="detail">{{ overview.downline_lv2 }}</label>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <img class="tier-container" src="/static/image/referral/referral_arrow_down.svg" width="auto" height="16" alt="krikya-referral" />
              </v-col>
              <v-col cols="12">
                <div class="tier-head pa-1 tier-container">
                  <img src="/static/image/referral/referral-people.svg" width="auto" height="75" alt="krikya-referral" />
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <img class="tier-info" v-bind="attrs" v-on="on" src="/static/image/referral/referral-info.svg" width="auto" height="25" alt="krikya-referral" />
                    </template>
                    <v-row no-gutters class="tier-tool-tip">
                      <v-col cols="12">
                        {{ stringFormat($t(`label.tier`), 3) }}
                      </v-col>
                      <v-col cols="12">
                        {{ stringFormat($t(`label.commissionTips`), getConfig(3)) }}
                      </v-col>
                    </v-row>
                  </v-tooltip>
                </div>
                <v-card class="primary text-center tier-container">
                  <v-card-text class="pa-1">
                    <v-row no-gutters>
                      <v-col class="no-gutters" cols="12">
                        <label class="detail">{{ overview.downline_lv3 }}</label>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="7">
        <v-row no-gutters>
          <v-col cols="5" v-if="!$vuetify.breakpoint.xsOnly">
            <v-card elevation="0" class="commission-content-holder full-height primary white--text text-center pa-2 mb-3">
              <v-card-text>
                <v-row justify="center" no-gutters>
                  <v-col cols="9" class="mb-3">
                    <label class="white--text">{{ $t(`label.commissionToRedeem`) }}</label>
                  </v-col>
                  <v-col cols="9" class="mb-0">
                    <v-form ref="redeemForm">
                      <app-form-field
                        :customClass="'commission-redeem-field input-field elevation-0 hide-details'"
                        :enabledLabel="false"
                        v-model.trim="redeemCommissionAmt"
                        :readonly="true"
                        :prefix="currencySymbolMapp(currentCurrency)"
                        :rules="validator.requiredRules()"
                      ></app-form-field>
                    </v-form>
                  </v-col>
                  <v-col cols="9">
                    <app-button customClass="dialog-button text-capitalize" :action="this.redeemCommissionConfirmationDialog" :title="$t(`button.redeem`)"></app-button>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <v-card elevation="0" class="commission-content-holder base pa-2 mb-3 full-height">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="10" :md="7" class="d-flex flex-column justify-center" style="margin:auto">
                    <v-row no-gutters>
                      <v-col>{{ $t(`label.id`) }}:</v-col>
                      <v-col class="font-weight-bold text-right">{{ overview.member_id }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>{{ $t(`field.referralCode`) }}:</v-col>
                      <v-col class="font-weight-bold text-right">{{ overview.my_referral_code }}</v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex justify-center">
                    <vue-qr-code v-if="overview.my_referral_code" class="qrCode" :text="share_url" :size="105"></vue-qr-code>
                  </v-col>
                  <v-col cols="12" md="12" sm="8" class="mb-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="8" lg="6">
                        <!-- <v-img class="mr-2" src="/static/svg/fb_btn.svg" @click="share()"> </v-img> -->
                        <v-btn class="dialog-button subtitle-1 full-width text-capitalize pa-2" @click="share()">
                          <v-icon class="mr-2">share</v-icon>
                          {{ webShareApiSupported ? $t(`button.shareNow`) : $t(`button.copy_ref`) }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:7%" light timeout="1000" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'check_circle' : 'cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
  </div>
</template>

<script>
import VueQrCode from 'vue-qrcode-component'
import AppLoginEmbedded from '@/components/member/loginEmbedded.vue'
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale, errorCodeHelper, formValidator } from '@/util'
import { SHARED } from '@/constants/constants'
import _ from 'lodash'
import { REFERRAL_GET_OVERVIEW, REFERRAL_POST_COMMISSION, REFERRAL_RESET_COMMISSION, REFERRAL_GET_CONFIG } from '@/store/referral.module'
import stringFormat from 'string-format'
import currencySymbolMapp from 'currency-symbol-map'

export default {
  name: 'referralSummary',
  components: {
    VueQrCode,
    AppLoginEmbedded
  },
  data: () => ({
    dateOfBirthMenu: false,
    stringFormat: stringFormat,
    currencySymbolMapp: currencySymbolMapp,
    language: uiHelper.getLanguage(),
    currentCurrency: uiHelper.getCurrency(),
    validator: formValidator,
    shared: SHARED,
    selectedTab: 'tier',
    dates: ['', ''],
    overview: {},
    redeemCommissionAmt: 0.0,
    share_url: '',
    alertShow: false,
    alertSuccess: false,
    alertMessage: ''
  }),
  created() {
    this.getReferralOverview()
    this.getReferralConfig()
  },
  mounted() {
    this.$refs.redeemForm.reset()
  },
  computed: {
    currentDate() {
      return new Date()
    },
    referralOverview() {
      return this.$store.state.referral.overview
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    totalPoint() {
      return this.overview.downline_lv1 + this.overview.downline_lv2 + this.overview.downline_lv3
    },
    redeemCommissionDetailResponseComplete() {
      return this.$store.state.referral.redeemCommissionResponse.complete
    },
    referralConfig() {
      return this.$store.state.referral.config
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    webShareApiSupported() {
      return navigator.share
    }
  },
  watch: {
    referralOverview() {
      this.overview = this.$store.state.referral.overview
      if (window.location.host.toLowerCase() === 'aa7813910.com') {
        this.share_url = 'https://www.jaya9.app' + '?refer_code=' + this.overview.my_referral_code
      } else {
        // If not, construct the share URL normally
        this.share_url = uiHelper.getHostname() + '?refer_code=' + this.overview.my_referral_code
      }
      this.redeemCommissionAmt = this.$store.state.referral.overview.commission_balance.toFixed(2)
    },
    redeemCommissionDetailResponseComplete() {
      let response = this.$store.state.referral.redeemCommissionResponse
      if (response.complete) {
        this.redeemCommissionResponseCompleteDialog(response)
        this.$store.dispatch(`${REFERRAL_RESET_COMMISSION}`)
      }
    }
  },
  methods: {
    getReferralConfig() {
      this.$store.dispatch(`${REFERRAL_GET_CONFIG}`)
    },
    getConfig(index) {
      let config = this.referralConfig.find(c => c.level == index)
      if (config) {
        return config.percentage
      } else {
        return 0
      }
    },
    openMsgDialog(selectedMsg) {
      this.msgDialog = {
        show: true,
        msg: selectedMsg.message,
        title: selectedMsg.subject
      }
    },
    closeMsgDialog() {
      this.msgDialog = {
        show: false
      }
    },
    getReferralOverview() {
      if (this.isLoggedIn) this.$store.dispatch(`${REFERRAL_GET_OVERVIEW}`)
    },
    redeemCommissionConfirmationDialog() {
      let self = this
      if (this.$refs.redeemForm.validate()) {
        let dialog = this.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.redeemConfirmation`)
        dialog.message.push(this.stringFormat(locale.getMessage(`message.ConfirmCommission`), self.$options.filters.currency(this.redeemCommissionAmt, this.currentCurrency)))
        dialog.button.push({
          title: locale.getMessage(`label.confirm`),
          action: () => this.redeemCommission()
        })
        this.openPageDialog(dialog)
      }
    },
    redeemCommission() {
      let obj = { amount: this.redeemCommissionAmt }
      this.$store.dispatch(`${REFERRAL_POST_COMMISSION}`, {
        obj
      })
    },
    redeemCommissionResponseCompleteDialog(response) {
      this.closePageDialog()
      if (response.code != 0 && !response.success) {
        this.$parent.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
      } else {
        this.$parent.$parent.openAlert(true, locale.getMessage(`message.commissionRedeemSuccess`))
      }
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    },
    share() {
      const sharing = {
        title: 'Refer a friend now and earn commission',
        text: 'Your referral code is ' + this.overview.my_referral_code + '.Refer a friend now and earn commission now! ',
        url: this.share_url
      }
      if (this.webShareApiSupported) {
        navigator.share(sharing)
      } else {
        let $f = this
        this.$copyText(this.share_url).then(function(e) {
          $f.alertMessage = 'Referral Link Copied!'
          $f.alertSuccess = true
          $f.alertShow = true
        })
      }
    }
  }
}
</script>

<style lang="scss">
.border-left-radius {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.border-right-radius {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.commission-redeem-field {
  input {
    text-align: right;
  }

  .v-input__slot {
    background-color: #fff !important;
  }
}

.media-btn {
  height: 50px;
  width: 100px;
}

.commission-content-holder {
  border-radius: 0px !important;

  .qrCode > img {
    border: 0px;
    padding: 0px;
    margin: auto;
  }

  .dialog-button {
    color: var(--v-text_reverse-base);
    background-color: var(--v-secondary-base) !important;
    width: 100%;
  }
}

.v-btn.theme-button {
  // background: #FDDD04;
  text-transform: capitalize;
  border-radius: 10px;
  height: auto;
  font-size: 16px;
}

.referral-tier {
  .tier-head {
    position: relative;
    display: flex;
    justify-content: center;

    .tier-info {
      position: absolute;
      bottom: 8px;
      right: 0;
      cursor: pointer;
    }
  }

  .tier-container {
    margin: auto;
    max-width: 180px;
  }

  .tier-tool-tip {
    color: #ffffff;

    .tier-tool-tip-icon {
      background-color: #ffffff;
      mask: url(/static/image/referral/referral-info.svg);
      mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask: url(/static/image/referral/referral-info.svg);
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      cursor: pointer;
    }
  }

  .detail {
    color: white;
  }

  .mobile-carousel {
    height: 350px !important;
  }
}
</style>
